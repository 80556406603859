// @flow

import * as types from '../types'
import directusClient from '../DirectusSDK'

import { loadStatus, loadObjects } from './tasks'

// import Api from '../../lib/api'

type Credentials = { username: string, password: string }
// type Authorization = { accessToken: string, expiresIn: string }

export const loadPermissions = () => (dispatch: Function) => {
  return directusClient
    .getMyPermissions()
    .then(result => {
      const { data } = result
      dispatch({ type: types.SET_USER_PERMISSIONS, data })
    })
    .catch(reason => {
      throw reason
    })
}

/**
 * Login a user
 * @param {Credentials} auth
 */
export const loginUser = (auth: Credentials): Function => (
  dispatch: Function
) => {
  const { username, password } = auth
  let userData = null

  return directusClient
    .login({
      email: username,
      password,
    })
    .then(result => {
      userData = result
      return result
    })
    .then(() => directusClient.getMe())
    .then(({ data }) => {
      userData = { ...userData, user: data }
      dispatch({ type: types.LOGIN_USER, auth: userData, directusClient })
      dispatch(loadStatus())
      dispatch(loadObjects())
      dispatch(loadPermissions())
    })
    .catch(reason => {
      // TODO: handle error
      throw reason
    })
}

export const continueUser = (): Function => (
  dispatch: Function
  // state: Function
) => {
  // const theClient = directusClient.login()
  // directusClient.refreshIfNeeded()

  if (directusClient.loggedIn) {
    dispatch({
      type: types.CONTINUE_USER,
      directusClient,
    })
    dispatch(loadStatus())
    dispatch(loadObjects())
    dispatch(loadPermissions())
  } else {
    dispatch({
      type: types.LOGOUT_USER,
    })
  }
  // dispatch(loadStatus())
}

/**
 * Logout a user
 */
export const logoutUser = () => (dispatch: Function) => {
  directusClient.logout()
  dispatch({
    type: types.LOGOUT_USER,
  })
}
