// import 'typeface-open-sans'

import './src/config/bootstrap/boostrap.scss'
import 'moment-timezone'
import moment from 'moment'
import momentDe from 'moment/locale/de'

import wrapWithProvider from './wrap-with-provider'

moment.updateLocale('de', momentDe)
moment.tz.setDefault('GMT')

export const wrapRootElement = wrapWithProvider
