// @flow

import * as types from '../types'
import directusClient from '../DirectusSDK'

// type TasksOptions = {}

/**
 * Loads tasks
 * @param {TasksOptions} options
 */

export const loadTasks = (
  page: number,
  query: string,
  filterMyTasks: boolean,
  filterCompletedTasks: boolean
) => (dispatch: Function, state: Function) => {
  // const objects = [{ objekt_id: '-1' }]
  // if (state().objects.length) objects.push(state().objects)
  const objects = state().objects || []
  // objects.push({ objekt_id: 'NULL' })
  const userId = (state().user || {}).id

  dispatch({
    type: types.LOAD_TASKS,
  })

  const params = {
    fields: '*.*,bemerkungen.*.*',
    filter: {
      objekt: {
        in: objects
          ? objects.map(({ objekt_id: objectId }) => objectId).join(',')
          : '',
        // eq: 'NULL',
        // logical: 'or',
      },
      ...(filterCompletedTasks && { status: { eq: 'done' } }),
      ...(filterMyTasks && { bearbeiter: { eq: userId } }),
    },
    q: query,
    // groups: 'objekt',
    sort: '-erstellungsdatum',
  }

  return directusClient
    .getItems('aufgaben', params)
    .then(result => {
      const { data } = result

      dispatch({
        type: types.SET_TASKS,
        data,
      })
      return result
    })
    .catch(reason => {
      throw reason
    })
}

export const loadStatus = () => (dispatch: Function) => {
  // const client = (state().user || {}).directusClient
  dispatch({ type: types.LOAD_TASKS_STATUS })

  return directusClient
    .getField('aufgaben', 'status')
    .then(result => {
      const { data } = result

      dispatch({ type: types.SET_TASKS_STATUS, data })

      return result
    })
    .catch(reason => {
      throw reason
    })
}

export const loadObjects = () => (dispatch: Function, state: Function) => {
  const id = (state().user || {}).id || null
  dispatch({ type: types.LOAD_OBJECTS })
  let allObjects = []

  const params = {
    filter: {
      nutzer_id: {
        eq: id,
      },
    },
    fields: 'objekt_id',
  }

  return directusClient
    .getItems('objekt', {
      sort: 'sortierung',
    })
    .then(result => {
      const { data } = result
      allObjects = data

      return directusClient.getItems('objekt_nutzer', params)
    })

    .then(result => {
      const { data } = result

      const objectsWithSort = data.map(o => ({
        ...o,
        sort:
          (allObjects.find(fO => o.objekt_id === fO.id) || {}).sortierung || -1,
      }))

      dispatch({ type: types.SET_OBJECTS, data: objectsWithSort })

      return result
    })
    .catch(reason => {
      throw reason
    })
}

export const setTaskProgress = (taskId: number, newStatus: string) => (
  dispatch: Function,
  state: Function
) => {
  const userId = (state().user || {}).id || null

  return directusClient
    .updateItem('aufgaben', taskId, {
      status: newStatus,
      bearbeiter: userId,
    })
    .then(result => {
      const { data } = result
      dispatch({ type: types.UPDATE_TASK_PROGRESS, data })
    })
    .catch(reason => {
      throw reason
    })
}

export const setTaskAsControlled = (taskId: number) => (
  dispatch: Function
  // state: Function
) => {
  // const userId = (state().user || {}).id || null

  return directusClient
    .updateItem('aufgaben', taskId, {
      kontrolliert: true,
    })
    .then(result => {
      const { data } = result
      dispatch({ type: types.MARK_TASK_AS_CONTROLLED, data })
    })
    .catch(reason => {
      throw reason
    })
}

export const addRemark = (taskId: number, text: string) => (
  dispatch: Function
  // state: Function
) => {
  return directusClient
    .createItem('bemerkungen', {
      text,
      aufgabe: taskId,
    })
    .then(result => {
      const { data } = result
      dispatch({ type: types.ADD_REMARK, data, taskId })
    })
    .catch(reason => {
      throw reason
    })
}

export const loadUserInfo = (userId: number, taskId: number) => (
  dispatch: Function
) => {
  // TODO: Check rights
  if (!userId) return

  return directusClient
    .getUser(userId, {
      fields: ['first_name', 'last_name', 'avatar.*'],
    })
    .then(result => {
      const { data } = result
      dispatch({ type: types.SET_TASK_USER_INFO, data, taskId })
    })
    .catch(reason => {
      throw reason
    })
}
