// @flow

import moment from 'moment'
import * as types from '../types'
import createReducer from '../../lib/createReducer'
// import clientSDK from '../DirectusSDK'

const initialState = {
  username: null,
  id: null,
  email: null,
  accessToken: null,
  isLoggedIn: false,
  expiresIn: null,
  expirationDate: null,
  loginTime: null,
}

// refreshToken: null,
// loginDuration: null,
// error: null,
// }

export const user = createReducer(initialState, {
  [types.LOGIN_USER](state, action) {
    const { auth, directusClient } = action
    const {
      token: accessToken,
      // url,
      localExp: expiresIn,
      user: { first_name: firstName, last_name: lastName, id, email: username },
    } = auth

    return {
      ...state,
      accessToken,
      isLoggedIn: true,
      expiresIn,
      expirationDate: moment()
        .add(expiresIn, 'milliseconds')
        .toDate(),
      loginTime: moment().toDate(),
      username,
      email: username,
      firstName,
      lastName,
      id,
      directusClient,
    }
  },
  [types.CONTINUE_USER](state, action) {
    const { directusClient } = action
    // const currentUser = client.getUser()

    const { token, localExp: expiresIn } = directusClient

    return {
      ...state, // username,
      accessToken: token,
      // apiUrl: url,
      isLoggedIn: true,
      expiresIn,
      expirationDate: moment()
        .add(expiresIn, 'seconds')
        .toDate(),
      // loginTime: Date.now(),
      directusClient,
    }

    // return state
  },
  [types.LOGOUT_USER]() {
    return initialState
  },
})

const initialStateUserPermissions = {
  isLoaded: false,
  permissions: null,
}

export const userPermissions = createReducer(initialStateUserPermissions, {
  [types.SET_USER_PERMISSIONS](state, action) {
    const { data: permissions } = action

    return {
      isLoaded: true,
      permissions,
    }
  },
})
