// @flow
import { combineReducers } from 'redux'

import * as UserReducer from './user'
import * as TasksReducer from './tasks'
// import * as ReviewReducer from './review'

export default combineReducers({ ...UserReducer, ...TasksReducer })

export {}
