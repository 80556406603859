// @flow
import React from 'react'
import { Provider } from 'react-redux'

// Persist
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import { store } from './src/store/configureStore'
import ActionCreators from './src/store/actions'

// import * as types from './src/store/types'

// const store = configureStore()

// const checkUser = () => {
//   const client = store.getState().directusClient || null
//   if (client && client..isLoggedIn) return

//   const { expirationDate, loginTime } = user
//   if (expirationDate > loginTime) {
//     store.dispatch({ type: types.LOGOUT_USER })
//   }
// }

const persistor = persistStore(store)

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: { element: Node }) => {
  store.dispatch(ActionCreators.continueUser())
  // store.dispatch(ActionCreators.loadStatus())
  // store.dispatch(ActionCreators.loadObjects())

  // if (typeof window !== 'undefined') {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
  // }

  // return <Provider store={store}>{element}</Provider>
}
