// @flow

// User
export const CONTINUE_USER = 'CONTINUE_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'

// Tasks
export const LOAD_TASKS = 'LOAD_TASKS'
export const SET_TASKS = 'SET_TASKS'
export const LOAD_TASKS_STATUS = 'LOAD_TASKS_STATUS'
export const SET_TASKS_STATUS = 'SET_TASKS_STATUS'
export const LOAD_OBJECTS = 'LOAD_OBJECTS'
export const SET_OBJECTS = 'SET_OBJECTS'
export const UPDATE_TASK_PROGRESS = 'UPDATE_TASK_PROGRESS'
export const MARK_TASK_AS_CONTROLLED = 'MARK_TASK_AS_CONTROLLED'
export const SET_TASK_USER_INFO = 'SET_TASK_USER_INFO'
export const ADD_REMARK = 'ADD_REMARK'
