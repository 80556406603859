module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aufgaben","short_name":"Aufgaben","start_url":"/","background_color":"#ffffff","theme_color":"#009fe3","display":"minimal-ui","icon":"src/images/app-logo-white.png","icons":[{"src":"/favicons/app-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/app-icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/app-icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/config/typography.js"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
