// @flow
export const black = '#000000'
export const white = '#ffffff'
export const gray100 = '#f8f9fa'
export const gray200 = '#ededed'
export const gray300 = '#dee2e6'
export const gray400 = '#ced4da'
export const gray500 = '#adb5bd'
export const gray600 = '#6c757d'
export const gray700 = '#495057'
export const gray800 = '#343a40'
export const gray900 = '#212121'
export const orange = '#ff6a01'
export const yellow = '#fbc02d'

export const grayDarker = gray900
export const gray = gray500
export const grayLight = gray400
export const grayLighter = gray200

export const brandColor = '#009FE3'
export const textColor = grayDarker

/* SPACES */
export const borderRadiusDefault = '3px'

// Directus colors
export const materialColors = (materialColor: string) => {
  const c = {
    white: '#ffffff',
    'yellow-700': '#fbc02d',
    'grey-700': '#616161',
    'blue-grey-200': '#b0bec5',
    'green-700': '#388e3c',
    'red-700': '#d32f2f',
  }

  return c[materialColor] || materialColor
}

/* FONTS */
export const fontFamilyBase = [
  // 'Open Sans',
  'BlinkMacSystemFont',
  '-apple-system',
  'Segoe UI',
  'Roboto',
  'Oxygen-Sans',
  'Ubuntu',
  'Helvetica Neue',
  'sans-serif',
].join(', ')
export const fontFamilyAccent = fontFamilyBase
