// @flow
import DirectusSDK from '@directus/sdk-js'

const client = new DirectusSDK({
  url: process.env.API_URL,
  project: process.env.PROJECT || '_',
  storage: typeof window !== `undefined` ? window.localStorage : null,
})

export default client
