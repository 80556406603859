// @flow

// import moment from 'moment'
import * as types from '../types'
import createReducer from '../../lib/createReducer'
import * as v from '../../config/variables'
// import { getHexFromMaterialColor } from '../../lib/utils'
// import clientSDK from '../DirectusSDK'

const initialState = {
  entries: [],
  pagination: null,
}

export const tasks = createReducer(initialState, {
  [types.SET_TASKS](state, action) {
    const { data } = action

    return { ...state, entries: data }
  },
  [types.LOGIN_USER]() {
    return initialState
  },
  [types.UPDATE_TASK_PROGRESS](state, action) {
    const { data: updatedTask } = action
    const { status, bearbeiter } = updatedTask

    const newEntries = state.entries.map(task =>
      task.id === updatedTask.id ? { ...task, status, bearbeiter } : task
    )
    return { ...state, entries: newEntries }
  },
  [types.MARK_TASK_AS_CONTROLLED](state, action) {
    const { data: updatedTask } = action
    const { kontrolliert } = updatedTask

    const newEntries = state.entries.map(task =>
      task.id === updatedTask.id ? { ...task, kontrolliert } : task
    )
    return { ...state, entries: newEntries }
  },
  [types.ADD_REMARK](state, action) {
    const { data: newlyAddedRemark, taskId } = action
    // const { status, bearbeiter, id } = newlyAddedRemark

    const newEntries = state.entries.map(task =>
      task.id === taskId
        ? { ...task, bemerkungen: task.bemerkungen.concat(newlyAddedRemark) }
        : task
    )
    return { ...state, entries: newEntries }
  },
  [types.SET_TASK_USER_INFO](state, action) {
    const { data: userInfo, taskId: updatedTaskId } = action

    const newEntries = state.entries.map(task =>
      task.id === updatedTaskId ? { ...task, assigneeInfo: userInfo } : task
    )
    return { ...state, entries: newEntries }
  },
})

export const status = createReducer(
  {},
  {
    [types.SET_TASKS_STATUS](state, action) {
      const {
        data: {
          options: { status_mapping: statusMapping },
        },
      } = action
      const newStatusMapping = Object.keys(statusMapping).reduce(
        (prev, curr) => {
          const bgColor = statusMapping[curr].background_color
          const textColor = statusMapping[curr].text_color

          const newCurr = Object.assign(statusMapping[curr], {
            background_color: v.materialColors(bgColor),
            text_color: v.materialColors(textColor),
          })

          const newPrev = Object.assign(prev)
          newPrev[curr] = newCurr

          return newPrev
        },
        {}
      )

      return newStatusMapping
    },
    [types.LOGIN_USER]() {
      return [] // initialState
    },
  }
)

export const objects = createReducer([], {
  [types.SET_OBJECTS](state, action) {
    const { data } = action

    // const cleanedObjects = data.map(({ objekt_id: objektId }) => objektId)

    return data
  },
  [types.LOGIN_USER]() {
    return [] // initialState
  },
})
